import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageContent from '../components/UI/PageContents';

const ContactUs = () => (
  <Layout headerText="Contact">
    <SEO title="Contact us" />
    <PageContent>
      <div className="row">
        <div className="col-md-6">
          <section className="mb-5">
            <h3>Our Office Address</h3>
            <p>
              Chilavanoor Road, Kadavanthara, Kochi, <br />
              Ernakulam, Kerala 682020
            </p>
          </section>
          <section className="mb-5">
            <h3>Call Us</h3>
            <p>
              <a href="tel:+91-9847423696 ">+91-98 4742 3696 </a>{' '}
              <a
                className="btn btn-sm btn-success px-3"
                href="tel:+91-9847423696 "
              >
                Call
              </a>
            </p>
          </section>
        </div>
        <div className="col-md-6">
          <section className="mb-5">
            <h3>Our Timing</h3>
            <p>You can call us at any time.</p>
            <p>Office timings: 8 am - 6 pm. Sunday holiday</p>
          </section>
          <div className="ml-md-3 text-center text-md-left py-4"></div>
        </div>
      </div>
    </PageContent>
  </Layout>
);

export default ContactUs;
